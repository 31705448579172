<template>
  <div class="page" v-if="detailInfo">
    <van-nav-bar title="家访详情" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <van-tabs
      class="tabs"
      v-model="currentTab"
      line-height="1px"
      line-width="4em"
      title-inactive-color="#41444E"
      title-active-color="#3C86FF"
    >
      <van-tab title="基本信息" name="basic">
        <CardBasic :detailInfo="detailInfo"></CardBasic>
      </van-tab>
      <van-tab title="进件资料" name="incoming">
        <CardAttachment :source="incomingFiles"></CardAttachment>
      </van-tab>
      <van-tab title="档案资料" name="attach">
        <CardAttachment :source="attachmentFiles"></CardAttachment>
      </van-tab>
      <van-tab title="审核记录" name="approval">
        <CardApproval
          :detailInfo="detailInfo"
          :applyInfo="applyInfo"
          @settle="goSettle(true)"
        ></CardApproval>
      </van-tab>
    </van-tabs>
    <div class="actions">
      <!-- <template
        v-if="['FEEDBACKING', 'APPROVALING'].includes(detailInfo.status)"
      >
        <van-button type="danger" @click="showCancelDialog">取消</van-button>
      </template> -->

      <!-- <template
        v-if="['FEEDBACKING', 'APPROVALING'].includes(detailInfo.status)"
      >
        <van-button type="primary" @click="goAdditional(false)"
          >补充信息</van-button
        >
      </template>
      <template v-else>
        <van-button type="primary" @click="goAdditional(true)"
          >补充信息详情</van-button
        >
      </template> -->
      <template>
        <van-button
          type="info"
          @click="onActionClick('FeedBackRequest', detailInfo)"
        >
          反 馈
        </van-button>
      </template>
      <template v-if="['PROCESSING'].includes(detailInfo.status)">
        <van-button type="primary" class="flex1" @click="goSettle(false)"
          >申请结案</van-button
        >
      </template>
      <template v-if="['APPROVALING'].includes(detailInfo.status)">
        <van-button type="primary" class="flex1" @click="goSettle(true)"
          >结案详情</van-button
        >
      </template>
    </div>

    <van-dialog
      v-model="showCancel"
      title="案件取消"
      show-cancel-button
      @confirm="submitCancel"
      :before-close="beforeClose"
    >
      <div class="dialog-form">
        <div class="title">请填写案件取消原因</div>
        <van-field
          v-model="form.approvalReason"
          rows="2"
          autosize
          :border="false"
          type="textarea"
          maxlength="50"
          placeholder="请输入取消原因"
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Api from "@/api/caseHandle";
import { Toast } from "vant";
export default {
  components: {
    CardBasic: () => import("./components/CardBasic"),
    CardAttachment: () => import("./components/CardAttachment"),
    CardApproval: () => import("./components/CardApproval"),
  },
  data() {
    return {
      homeNo: this.$route.params.homeNo,
      showCancel: false,
      currentTab: this.$route.query.currentTab || "basic",

      form: {
        approvalReason: "",
      },

      detailInfo: null,
      applyInfo: null,
      attachmentFiles:null,//档案资料
      incomingFiles:null,//进件资料
    };
  },
  watch: {
    currentTab: {
      immediate: true,
      handler(val) {
        const targetUrl = this.$router.resolve({
          name: this.$route.name,
          params: this.$route.param,
          query: {
            ...this.$route.query,
            currentTab: val,
          },
        }).href;
        history.replaceState(null, "", targetUrl);
      },
    },
  },
  methods: {
    onActionClick(routeName, item) {
      this.$router.push({
        name: routeName,
        query: { homeNo: item.homeNo, assignNo: item.assignNo, orgName: item.homeBasicDetailDTO.orgName,homeStatus:item.status },
      });
    },
    showCancelDialog() {
      this.form.approvalReason = "";
      this.showCancel = true;
    },

    goAdditional(readonly) {
      const query = {};
      if (readonly) {
        query.readonly = readonly;
      }
      this.$router.push({
        name: "Additional",
        params: {
          homeno: this.homeNo,
        },
        query,
      });
    },

    goSettle(readonly) {
      const query = {};
      if (readonly) {
        query.readonly = readonly;
      }
      const { detailInfo } = this;
      this.$router.push({
        name: "Settle",
        params: {
          homeno: this.homeNo,
        },
        query: {
          ...query,
          homeNo: detailInfo.homeNo,
          assignNo: detailInfo.assignNo,
        },
      });
    },

    // 加载详情
    async loadDetail() {
      const loading = Toast.loading();
      try {
        const res = await Api.findDetailByHomeNo(this.homeNo);
        this.detailInfo = res.data;
        this.attachmentFiles = this.detailInfo.assignRecordDTO.assignFiles ||{};
        this.incomingFiles = this.detailInfo.assignRecordDTO.assignFiles2||{};
        this.getFinishApply(this.detailInfo.assignNo);
        loading.close();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 结案详情
    async getFinishApply(assignNo) {
      try {
        const res = await Api.getFinishApply(assignNo);
        const { data } = res;
        this.applyInfo = data;
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 取消
    async submitCancel() {
      Toast.loading();
      try {
        const res = await Api.cancel({
          ...this.form,
          homeNo: this.homeNo,
        });
        this.showCancel = false;
        await Toast.success({
          message: res.message,
          onClose: () => {
            this.loadDetail();
          },
        });
      } catch (error) {
        Toast.fail(error);
      }
    },

    beforeClose(action, done) {
      if (action === "confirm") {
        return done(false);
      }
      done();
    },
  },
  mounted() {
    this.loadDetail();
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";
.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .actions {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding: 13px 13px;
    .van-button {
      border-radius: 3px;
      margin-right: 8px;
      height: 36px;
      line-height: 36px;
      &:last-child {
        margin-right: 0;
      }
    }
    .flex1 {
      flex: 1;
    }
  }
}
.tabs {
  flex: 1;
  overflow-y: auto;

  /deep/ .van-tabs__content {
    flex: 1;
    .van-tab__pane {
      background: @pageBg;
    }
  }

  /deep/ .van-tabs__nav--line {
    justify-content: space-between;
    padding-bottom: 10px;
  }
  /deep/ .van-tabs__line {
    background: #3c86ff;
  }
}

.dialog-form {
  padding: 10px 10px;
  .title {
    text-align: center;
    font-size: 15px;
    margin: 10px 0px;
  }
  .van-field {
    margin: 5px 0px 10px 0px;
    border: 1px solid rgba(119, 119, 119, 0.5);
    border-radius: 5px;
  }
}
</style>